<template>
    <div class="gamePlay">
        <!-- <loading v-if="!gameShow" /> -->
        <div></div>
        <iframe width="100%" height="100%" class="iframe" id="gamePage" ref="gamePage" :src="src" frameborder="0" allowfullscreen="true" v-if="src"></iframe>
        <div class="back" @click="back">返回</div>
    </div>
</template>

<script>
    import { gameUrl } from "@/api/game";
    export default {
        data() {
            return {
                src: '', //游戏地址
                gameShow: false, //是否显示游戏
            }
        },
        created() {
            this.gamePlay();
            sessionStorage.setItem('from', 'game');
        },
        // mounted() {
        //     // console.log(document.getElementById('gamePage'));
        //     // console.log(this.$refs.gamePage)
        // },
        methods: {
            back() {
                this.$router.push('/game');
                this.gameShow = false;
            },
            async gamePlay() {
                this.$store.commit('app/SET_LOADING', true);
                let res = await this.$Api(gameUrl, {game: (this.$route.query.id).toString()});
                if (res.code == 200) {
                    // console.log(res.data)
                    this.gameShow = true;
                    this.src = res.data.gameURL;
                    
                }
                this.$store.commit('app/SET_LOADING', false);
            }
        },
    }
</script>
<style scoped>
    .gamePlay {
        width: 100%;
        height: 100%;
    }
    .iframe {
        width: 100%;
        height: 100%;
    }

    .back {
        position: absolute;
        top: 10px;
        right: 23%;
        width: 0.8rem;
        height: 0.8rem;
        background: #ffffff;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        line-height: 0.8rem;
        color: #000000;
        transform: rotate(90deg);
    }
</style>